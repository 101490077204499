<template>
    <div class="tags">
        <span class="tag" :class="{
            'is-info' : task.Status == 'ASSIGNED' || task.Status == 'ASSIGNED_RERAISE',
            'is-warning' : task.Status == 'UNASSIGNED',
            'is-danger' : task.Status == 'BLOCKED',
            'is-dark' : task.Status == 'CANCELLED' || task.Status == 'CANCEL_SUBMITTED',
            'is-success' : task.Status == 'SUBMITTED' || task.Status == 'COMPLETED' || task.Status == 'RERAISED',
        }">{{ task.Status }}</span>
        <attachment-task :task="task" />
        <span v-if="!checkHash(task)" class="">
            <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 24 24" fill="none" stroke="#d0021b" stroke-width="2" stroke-linecap="round" stroke-linejoin="arcs"><path d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z"></path><line x1="12" y1="9" x2="12" y2="13"></line><line x1="12" y1="17" x2="12" y2="17"></line></svg>
        </span>
    </div>
</template>


<script>
import AttachmentTask from './AttachmentTag.vue'
export default {
    props: ['task'],
    components:{
        AttachmentTask,
    },
    methods:{
        checkHash(task){
            return this.$store.getters.om_taskCheck(task)
        }
    }
}
</script>

