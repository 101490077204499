<template>
    <div>
        <div class="column" style="border-bottom: solid 1px #ccc;">
            <div class="level">
                <div class="level-start">
                    <input type="text" class="input is-rounded" placeholder="Filter"  v-model="filterKeyword" >
                </div>
                <div class="level-end">
                    <div class="field is-grouped">
                        <div class="control">
                            <div class="field has-addons">
                                <div class="control">
                                    <a @click="updateDiff(-1)" class="button is-small">&laquo;</a>
                                </div>
                                <div class="control">
                                    <div class="button is-small is-static">{{dateRangeDis}}</div>
                                </div>
                                
                                <div class="control">
                                    <div class="select is-small">
                                        <select v-model="filterType">
                                            <option value="all">All</option>
                                            <option value="day">Daily</option>
                                            <option value="week">Week</option>
                                            <option value="month">Month</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="control">
                                    <a @click="filterDiff = 0" class="button is-small">Reset</a>
                                </div>
                                <div class="control">
                                    <a :disabled="filterDiff >= 0" @click="updateDiff(1)" class="button is-small">&raquo;</a>
                                </div>
                            </div>
                        </div>
                        <div class="control"><sorter :data="filteredTasksByKeyword" @sorted="onSorted" :sortfields="{
                                'updatedAt': 'Update At',
                                'details.TaskID': 'Task ID',
                                'details.project.id': 'project',
                                'details.customer': 'customer',
                                'details.Segment/Unit': 'Segment/Unit',
                                'CreatedBy.name': 'Uploaded by',
                                'PK': 'Jobsheet',
                                'GS1': 'Processor',
                                'Status': 'Status',
                            }" />
                        </div>
                        <div class="control">
                            <export-excel :data=sortedTasks />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <table class="table is-fullwidth is-hoverable">
            <thead>
                <tr>
                    <th width="2">&nbsp;</th>
                    <th>Task</th>
                    <th>Project</th>
                    <th>Customer</th>
                    <th>Ref</th>
                    <th>RFS/RFT</th>
                    <th>Jobsheet</th>
                    <th>Updates</th>
                    <th>
                        Status
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr  class="is-clickable" :class="{'is-selected': selectedSessionTasks[task.task_id], 'is-viewed' : isViewed(task) }" @click="viewTask(task)" v-for="(task,i) in sortedTasks" :key="i">
                    <td>
                        <span class="has-text-grey-lighter">{{ i+1 }}</span>

                    </td>
                    <td>
                        <span class="has-text-weight-semibold">{{ task.TaskID | shortKey}}</span>
                        <span class="heading">{{ task.RequestType}}</span>
                    </td>
                    <td>
                        <span class="">{{ getProjectName(task.details.project.id) }}</span>
                    </td>
                    <td>
                        <span class="has-text-weight-semibold">{{ task.details['Customer Name'] }}</span><br>
                        <span>{{ task['Product/Service']}}</span>
                        <span class="heading">{{ task['Segment/Unit'] }}</span>
                    </td>
                    <td>
                        <div v-if="task.details['Ref No']">
                            <span class="heading">Ref no.</span>
                            {{ task.details['Ref No'] }}
                        </div>
                        
                        <div v-if="task.details['Qoute Name']">
                            <span class="heading">Quote name</span>
                            {{ task.details['Qoute Name'] }}
                        </div>

                        <div v-if="task.details['Quote Name']">
                            <span class="heading">Quote name</span>
                            {{ task.details['Quote Name'] }}
                        </div>

                        <div v-if="task.details['Site ID/Site Name']">
                            <span class="heading">Site name</span>
                            {{ task.details['Site ID/Site Name'] }}
                        </div>
                    </td>
                    <td>
                        {{task.details['RFS Date'] || task.details['RFT Date']}}
                    </td>
                    <td>
                        <span> {{ task.PK | shortKey}}</span>
                        <span class="heading">Uploaded by {{ task.CreatedBy.name }} on {{ task.createdAt | formatDate}} ({{ task.createdAt | diffNow}})</span>
                    </td>
                    <td>
                        <span class="tooltip is-tooltip-right" data-tooltip="Order no.">{{ (task.feedback || {}).order_no }}</span>
                        <span class="heading tooltip is-tooltip-right" data-tooltip="Service ID">{{ (task.feedback || {}).service_id }}</span>
                        <span class="heading tooltip is-tooltip-right" data-tooltip="Blockage type">{{ (task.feedback || {}).blockage_type }}</span>
                        <span class="has-text-grey-light" v-if="!task.feedback">N/A</span>
                    </td>
                    <td class="has-text-right">
                        <div class="level">
                            <div class="level-start">

                            </div>
                            <div class="level-end">
                                <div class="level-item">
                                    <task-status :task="task" />
                                </div>
                            </div>
                        </div>

                        <span class="help">{{ getProcessorName(task.GS1 )}}</span>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>


<script>
import moment from 'moment'
import * as R from 'ramda'
import ExportExcel from './../ExportExcel.vue'
import AttachmentTag from './../AttachmentTag.vue'
import TaskStatus from './../TaskStatus.vue'
import Sorter from './Sorter.vue'

export default {
    components:{
        ExportExcel,  
        AttachmentTag,
        TaskStatus,
        Sorter,
    },
    props:['filteredTasks', 'getProjectName', 'isViewed','selectedSessionTasks', 'getProcessorName', 'viewTask', 'defaultType'],
    data(){
        return {
            filterKeyword: '',
            sortedTasks:[],
            filterType: 'all',
            filterDiff: 0,
        }
    },
    mounted(){
        if(this.defaultType){
            this.filterType = this.defaultType
        }
    },
    computed:{
        filteredTasksByKeyword(){
            return this.filteredTasks
            .filter(data => {
                if(this.filterType == 'all'){
                    return true
                }
                return this.dateRange[0].isSameOrBefore(data.updatedAt, 'day') && 
                this.dateRange[1].isAfter(data.updatedAt, 'day')
            })
            .filter( data => JSON.stringify(data).toLowerCase().indexOf(this.filterKeyword) > -1)
        },
        dateRange(){
            return [
                moment().startOf(this.filterType).add(this.filterDiff , this.filterType),
                moment().startOf(this.filterType).add(this.filterDiff + 1 , this.filterType),
            ]
        },
        dateRangeDis(){
            switch(this.filterType){
                case "day":
                    return this.dateRange[0].format('DD MMM YYYY')
                case "week":
                    return R.pipe(
                        R.map(date => date.format('DD MMM YYYY')),
                        R.join(' → ')
                    )(this.dateRange)
                case "month":
                    return this.dateRange[0].format('MMMM YYYY')
                case 'all':
                    return "All"
            }
            
        }
    },

    watch:{
        filterType(){
            this.filterDiff = 0
        }
    },

    methods:{
        updateDiff(diff){
            if(diff == 1 && this.filterDiff < 0){
                this.filterDiff++
            }else if(diff == -1 ){
                this.filterDiff--
            }
        },
        onSorted(sorted){
            this.sortedTasks = sorted
        },
    },
    filters:{
        diffNow(value){
            return moment(value).fromNow()
        },
        shortKey(value){
            return R.take(12, value)
        },
        formatDate(str){
            return moment(str).format('DD MMM YYYY, hh:mm A')
        },
        formatTime(str){
            return moment(str).format('hh:mm A')
        }
    }
}
</script>
