<template>
    <div class="card-content">
        <div v-if="jobsheet && project" >
            <div class="level" :class="{'is-quickview': task}">
                <div>
                    <router-link :to="`/jobs/projects/${project.PK}`" class="heading"> &laquo; {{project.name}} Project</router-link>
                    <h1 class="title is-5 has-text-grey">{{ jobsheet.PK | shortKey }}</h1>
                </div>
                <div>
                    <div class="field">
                        <input id="switchRoundedDefault" type="checkbox" name="switchRoundedDefault" class="switch is-rounded  is-rtl" v-model="assignment_mode">
                        <label for="switchRoundedDefault" class="is-size-6">Assign task</label>
                    </div>
                </div>
            </div>
            <div class="tabs is-boxed">
                <ul>
                    <li :class="{'is-active': tab == 'tasks'}">
                        <a @click="tab = 'tasks'">
                            <span>Tasks</span>
                        </a>
                    </li>
                    <li :class="{'is-active': tab == 'distributions'}">
                        <a @click="tab = 'distributions'">
                            <span>Distributions</span>
                        </a>
                    </li>
                    <li v-if="canDeleteJobsheet" :class="{'is-active': tab == 'delete'}">
                        <a @click="tab = 'delete'" class="has-text-danger"> 
                            <span class="icon is-small">
                                <i class="fas fa-image" aria-hidden="true">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill:currentColor;">
                                        <path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zm2.46-7.12l1.41-1.41L12 12.59l2.12-2.12 1.41 1.41L13.41 14l2.12 2.12-1.41 1.41L12 15.41l-2.12 2.12-1.41-1.41L10.59 14l-2.13-2.12zM15.5 4l-1-1h-5l-1 1H5v2h14V4z"/>
                                    </svg>
                                </i>
                            </span>
                            <span>Delete Jobsheet</span>
                        </a>
                    </li>
                </ul>
            </div>
            <div class="columns" :class="{'is-quickview': task}">
                <div v-if="tab != 'delete'" class="column">
                    <div v-if="tab == 'distributions'">
                        <div class="card" style="margin-bottom: 50px;">
                            <table class="table is-fullwidth is-hoverable">
                                <thead>
                                    <tr>
                                        <th>Staff ID</th>
                                        <th>Name</th>
                                        <th>Total tasks</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr @click="selectedDistribution = staff_id" v-for="(distribution, staff_id) in distributions" :key="staff_id" :class="{
                                        'is-selected': selectedDistribution == staff_id
                                    }">
                                        <td>{{ staff_id }}</td>
                                        <td>{{ getProcessorName(staff_id)}}</td>
                                        <td>{{ (distribution||[]).length}}</td>
                                    </tr>
                                    <tr v-if="noDistributions"> 
                                        <td colspan="3">
                                            NO DISTRIBUTION YET
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="card" v-if="filteredTasks.length > 0">
                        <table-tasks @taskSelected="viewTask" :tasks="filteredTasks" :isSelected="isSelected" :processors="processorsMapping" />
                    </div>
                </div>

                <div v-else class="column">
                    <div class="">
                        <div class="notification is-warning">
                            <h2 class="title is-4">Delete Jobsheet Confirmation</h2>
                            <p>Are you sure want to delete the jobsheet? This action will remove all records related to this jobsheet.</p>
                        </div>
                        <div class="buttons">
                            <a @click="handleDelete" class="button is-danger" :class="{'is-loading': isLoading}">Confirm delete</a>
                            <a @click="tab = 'tasks'" class="button is-text">Cancel</a>
                        </div>
                    </div>
                </div>
                
                <quickview-task :task="task" @close="task = false" @blocked="loadData" />

                
            </div>
        </div>
        <div v-if="assignment_mode" style="height:150px; ">
            <task-assignment :processors="processors" :isLoading="isLoading" :total_selected="total_selected" :selectAll="selectAll" :clearSelection="clearSelection" @assign="handleAssign" @close="assignment_mode = false"/>
        </div>
    </div>
</template>


<script>

import * as R from 'ramda'
import moment from 'moment'
import TableTasks from './../TableTasks.vue'
import QuickviewTask from './../QuickviewTask.vue'
import TaskSM from './../../domain/TaskStates.js'
import TaskAssignment from './TaskAssignment.vue'

export default {
    components:{
        TableTasks,
        QuickviewTask,
        TaskAssignment,
    },
    data(){
        return {
            tab: 'tasks',
            task: false,
            assignment_mode: false,
            selected_tasks: {},
            processor: false,
            isLoading: false,
            selectedDistribution: false,
        }
    },
    computed:{
        noDistributions(){
            return R.keys(this.distributions).length == 0
        },
        project_id(){
            return this.$route.params.project_id
        },
        project(){
            return this.$store.state.om.projects[this.project_id]
        },
        jobsheet_id(){
            return this.$route.params.jobsheet_id
        },
        jobsheets(){
            return this.$store.state.om.jobs
        },
        jobsheet(){
            return this.jobsheets[this.jobsheet_id]
        },
        filteredTasks(){
            if(this.selectedDistribution && this.tab == 'distributions'){
                return this.distributions[this.selectedDistribution] || []
            }

            if(this.tab == 'tasks'){
                return R.pipe(
                    R.sortBy(R.prop('TaskID')),
                )(this.jobsheet.tasks || [])
            }

            return []
        },
        total_selected(){
            return R.pipe(R.values,R.sum)(this.selected_tasks)
        },
        processors(){
            return this.$store.state.om.processors
        },
        processorsMapping(){
            return R.indexBy(R.prop('staff_id'), this.processors)
        },
        distributions(){
            return R.pipe(
                R.filter(task => task.Status != 'UNASSIGNED'),
                R.groupBy(R.prop('GS1'))
            )(this.jobsheet.tasks)
        },
        canDeleteJobsheet(){
            return this.jobsheet.CreatedBy.staff_id == this.$store.state.login.user.staff_id || this.$store.getters.userScope('developer')
        }
    },
    methods:{
        handleDelete(){
            this.isLoading = true
            this.$store.dispatch('om.jobsheet.delete', this.jobsheet_id).then(() => {
                this.isLoading = false
                alert('Jobsheet deleted!')
                this.$router.push(`/jobs/projects/${this.project_id}`)
            })
        },  
        getProcessorName(staff_id){
            return (this.processorsMapping[staff_id] || { name: ''}).name 
        },
        handleAssign(processor){
            if(this.isLoading){
                return
            }

            this.isLoading  = true
            this.$store.dispatch('om.processor.assign', {
                processor: processor,
                tasks: this.jobsheet.tasks.filter(task => this.selected_tasks[task.TaskID])
            }).then(() => {
                this.isLoading  = false
                this.loadData()
                this.selected_tasks = {}
                this.processor = ''
            }).catch(err => {
                this.isLoading = false
            })
        },
        selectAll(){
            this.$set(this, 'selected_tasks', R.pipe(
                R.indexBy(R.prop('TaskID')),
                R.map(() => true)
            )(this.filteredTasks))
        },
        clearSelection(){
            this.selected_tasks = {}  
        },
        isSelected(task){
            return this.assignment_mode && this.selected_tasks[task.TaskID]
        },
        loadData(){
            return Promise.all([
                this.$store.dispatch('om.processors.list'),
                this.$store.dispatch('om.project.load', this.project_id),
                this.$store.dispatch('om.jobsheet.load', this.jobsheet_id)
            ])
        },
        canAssign(task){
            return TaskSM(task).canTransTo('ASSIGNED') || TaskSM(task).canTransTo('ASSIGNED_RERAISE')
        },
        
        viewTask(task){
            if(!this.assignment_mode){
                this.task = task
            }else{
                if(!this.canAssign(task)){
                    alert('You cannot assign this task')
                    return
                }
                this.$set(this.selected_tasks, task.TaskID, !this.selected_tasks[task.TaskID])
            }
        }
    },
    mounted(){
        this.loadData().then(()=>{
            this.$store.dispatch('isLoaded')
        })
    },
    filters:{
        shortKey(value){
            return R.take(12, value)
        },
        formatDate(value){
            return moment(value).format('DD MMM YYYY')
        }
    }
}
</script>
