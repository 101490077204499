<template>
    <div>
        <div class="column" style="border-bottom: solid 1px #ccc;">
            <div class="level">
                <div class="level-start">
                    <div class="level-item">
                        <div class="field is-grouped">
                            <div class="control">
                                <input v-model="filterKeyword" type="text" class="input is-rounded" placeholder="Filter">
                            </div>
                            <div class="control">
                                <span class="help">Showing <strong>{{ filteredTasksByKeyword.length }}</strong> records</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="level-end">
                    <div class="field is-grouped">
                        <div class="control">
                            <div class="field has-addons">
                                <div class="control">
                                    <a @click="updateDiff(-1)" class="button is-small">&laquo;</a>
                                </div>
                                <div class="control">
                                    <div class="button is-small is-static">{{dateRangeDis}}</div>
                                </div>
                                
                                <div class="control">
                                    <div class="select is-small">
                                        <select v-model="filterType">
                                            <option value="all">All</option>
                                            <option value="day">Daily</option>
                                            <option value="week">Week</option>
                                            <option value="month">Month</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="control">
                                    <a @click="filterDiff = 0" class="button is-small">Reset</a>
                                </div>
                                <div class="control">
                                    <a :disabled="filterDiff >= 0" @click="updateDiff(1)" class="button is-small">&raquo;</a>
                                </div>
                            </div>
                        </div>
                        <div class="control"><sorter :data="filteredTasksByKeyword" @sorted="onSorted"  :sortfields="{
                                'updatedAt': 'Update At',
                                'details.TaskID': 'Task ID',
                                'details.project.id': 'project',
                                'details.customer': 'customer',
                                'details.Segment/Unit': 'Segment/Unit',
                                'CreatedBy.name': 'Uploaded by',
                                'GS1': 'Processor',
                                'Status': 'Status',
                            }" /></div>
                        <div class="control">
                            <export-excel :data=sortedTasks />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <table class="table is-fullwidth is-hoverable">
            <thead>
                <tr>
                    <th width="2">&nbsp;</th>
                    <th>Task</th>
                    <th>Project</th>
                    <th>Customer</th>
                    <th>Ref</th>
                    <th>RFS/RFT</th>
                    <th>Uploaded</th>
                    <th>
                        Status
                        
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr @click="viewTask(task)" v-for="(task,i) in sortedTasks" :key="i" :class="{'is-selected': isSelected(task)}">
                    <td><span class="has-text-grey-lighter">{{ i+1 }}</span> </td>
                    <td>
                        <span class="has-text-weight-semibold">{{ task.TaskID | shortKey}}</span>
                        <span class="heading">{{ task.RequestType}}</span>
                    </td>
                    <td>
                        {{ getProjectName(task.details.project.id)}}
                    </td>
                    <td>
                        <span class="has-text-weight-semibold">{{ task.details['Customer Name'] }}</span><br>
                        <span>{{ task['Product/Service']}}</span>
                        <span class="heading">{{ task['Segment/Unit'] }}</span>
                    </td>
                    <td>
                        <div v-if="task.details['Ref No']">
                            <span class="heading">Ref no.</span>
                            {{ task.details['Ref No'] }}
                        </div>
                        
                        <div v-if="task.details['Qoute Name']">
                            <span class="heading">Quote name</span>
                            {{ task.details['Qoute Name'] }}
                        </div>

                        <div v-if="task.details['Quote Name']">
                            <span class="heading">Quote name</span>
                            {{ task.details['Quote Name'] }}
                        </div>

                        <div v-if="task.details['Site ID/Site Name']">
                            <span class="heading">Site name</span>
                            {{ task.details['Site ID/Site Name'] }}
                        </div>
                    </td>
                    <td>
                        {{task.details['RFS Date'] || task.details['RFT Date']}}
                    </td>
                    <td>
                        {{ task.CreatedBy.name }}
                        <span class="heading">{{ task.createdAt | formatDate}} ({{ task.createdAt | fromNow}})</span>
                    </td>
                    <td>
                        <task-status :task="task" />
                        <span class="help">{{ getProcessorName(task.GS1 )}}</span>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>


<script>
import moment from 'moment'
import * as R from 'ramda'
import AttachmentTask from './AttachmentTag.vue'
import TaskStatus from './TaskStatus.vue'
import ExportExcel from './ExportExcel.vue'
import TaskSM from './../domain/TaskStates.js'
import Sorter from './Tasks/Sorter.vue'
const hash = require('sha1')

export default {
    components:{
        AttachmentTask,
        ExportExcel,
        TaskStatus,
        Sorter,
    },
    props:['tasks','isSelected', 'processors', 'task', 'defaultFilter', 'defaultFilterType'],
    data(){
        return {
            filterKeyword: this.defaultFilter || '',
            sortedTasks: [],
            filterType: this.defaultFilterType || 'all',
            filterDiff: 0,
        }
    },
    computed:{
        filteredTasksByKeyword(){
            return this.tasks
            .filter(data => {
                if(this.filterType == 'all'){
                    return true
                }
                return this.dateRange[0].isSameOrBefore(data.updatedAt, 'day') && 
                this.dateRange[1].isAfter(data.updatedAt, 'day')
            })
            .filter(data => JSON.stringify(R.omit(['details'],data)).toLowerCase().indexOf(this.filterKeyword.toLowerCase()) > -1)
        },
        dateRange(){
            return [
                moment().startOf(this.filterType).add(this.filterDiff , this.filterType),
                moment().startOf(this.filterType).add(this.filterDiff + 1 , this.filterType),
            ]
        },
        dateRangeDis(){
            switch(this.filterType){
                case "day":
                    return this.dateRange[0].format('DD MMM YYYY')
                case "week":
                    return R.pipe(
                        R.map(date => date.format('DD MMM YYYY')),
                        R.join(' → ')
                    )(this.dateRange)
                case "month":
                    return this.dateRange[0].format('MMMM YYYY')
                case 'all':
                    return "All"
            }
            
        }
    },
    methods:{
        onSorted(sorted){
            this.sortedTasks = sorted
        },  
        canAssign(task){
            return TaskSM(task).canTransTo('ASSIGNED') || TaskSM(task).canTransTo('ASSIGNED_RERAISE')
        },
        viewTask(task){
            this.$emit('taskSelected', task )
        },
        getProcessorName(staff_id){
            return (this.processors[staff_id] || { name: ''}).name 
        },
        getProjectName(project_id){
            return (this.$store.state.om.projects[project_id] || { name: project_id }).name
        },
        updateDiff(diff){
            if(diff == 1 && this.filterDiff < 0){
                this.filterDiff++
            }else if(diff == -1 ){
                this.filterDiff--
            }
        },
    },
    filters:{
        shortKey(value){
            return R.take(12, value)
        },
        formatDate(value){
            return moment(value).format('DD MMM YYYY, hh:mm A')
        },
        fromNow(value){
             return moment(value).fromNow()
        }
    }
}
</script>
