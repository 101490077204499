<template>
    <div>
        <div  class="sectionheader">
            <div class="card-content has-background-light">
                <div class="level">
                    <div>
                        <span><span class="title is-5">Task Management</span></span>
                    </div>
                </div>
            </div>
        </div>
        <div class="column">
            <div class="columns">
            <div class="column fullheight-scroller has-background-white-bis is-paddingless is-hidden-mobile is-2" style="border-right:solid 1px hsl(0, 0%, 86%);">
                <div class="column">
                    <aside class="menu" style="padding-bottom: 40px;">
                        <p class="menu-label">
                            Tasks
                        </p>
                        <ul class="menu-list">
                            <li><router-link :to="`/tasks/open`">Open tasks</router-link></li>
                            <li><router-link :to="`/tasks/blocked`">Blocked tasks</router-link></li>
                            <li><router-link :to="`/tasks/recent`">Recent tasks</router-link></li>
                        </ul>
                        
                        <div class="menu-label">
                            <div class="level">
                                <div class="badge">Projects</div>
                            </div>
                        </div>
                        <ul class="menu-list">
                            <li style="margin-bottom:10px;">
                                <input type="text" v-model="filterProject" class="input is-rounded is-small" placeholder="filter">
                            </li>
                            <li v-for="project in projects" :key="project.PK">
                                <router-link :to="`/tasks/projects/${project.PK}`"> {{ project.name }} <span v-if="hasProject(project.PK)" class="has-text-success">&bull;</span> </router-link>
                            </li>
                            <li v-if="isLoading('projects')">
                                <span class="button is-text is-loading is-fullwidth"></span>
                            </li>
                        </ul>                        
                    </aside>
                </div>
            </div>
            <div class="column fullheight-scroller-2  ">

                <router-view></router-view>
            </div>
        </div>
        </div>
    </div>
</template>

<script>
import * as R from 'ramda'
export default {
    data(){
        return {
            filterProject: ''
        }
    },
    computed:{
        projects(){
            return R.filter(data => data.name.toLowerCase().indexOf(this.filterProject.toLowerCase()) > -1, this.$store.state.om.projects)
        },
        assignedProjects(){
            return this.$store.getters.om_assignedProjects
        },
    },
    methods:{
        isLoading(key){
            return this.$store.state.om.loadings[key]
        },
        hasProject(project_pk){
            return this.assignedProjects[project_pk]
        }
        
    },
}
</script>

<style lang="sass">
@import "~bulma/sass/utilities/functions";
@import "~bulma/sass/utilities/initial-variables";
@import "~bulma/sass/utilities/derived-variables";
@import "~bulma/sass/utilities/mixins.sass"

.sectionheader
    height: 70px
    overflow: hidden
    border-bottom: solid 1px hsl(0, 0%, 86%)


.fullheight-scroller
    height: calc(100vh - 122px)
    overflow: scroll
    min-width: 240px
    z-index:0 !important


.fullheight-scroller-2
    max-width: calc(100% - 240px)
    height: calc(100vh - 122px)
    overflow: scroll

    +mobile
        min-width: 100%
    


.is-fixed
    position: fixed




.menu-list .router-link-active
    background: #FF6600
    color: #ffffff !important


.menu-list .router-link-active:hover
    background: #FF660099

</style>