<template>
    <div>
        <div class="column" style="border-bottom: solid 1px #ccc;">
            <div class="level">
                <div class="level-start">
                    <input type="text" v-model="filterKeyword" class="input is-rounded" placeholder="filter">
                </div>
            </div>
        </div>
        <table  class="table is-fullwidth is-hoverable">
            <thead>
                <tr>
                    <th>Jobsheet ID</th>
                    <th>Uploaded By</th>
                    <th>Filename</th>
                    <th @click="sortTable('createdAt')" class="is-clickable"><span v-if="sortKey == 'createdAt'" class="icon is-pulled-right" v-html="sortIcon"></span><abbr title="Sort by this">Created At</abbr></th>
                    <th @click="sortTable('TaskCounter')" class="is-clickable"><span v-if="sortKey == 'TaskCounter'" v-html="sortIcon" class="icon is-pulled-right"></span><abbr title="Sort by this">Task Counter</abbr> </th>
                    <th @click="sortTable('Status')" class="is-clickable"><span v-if="sortKey == 'Status'" v-html="sortIcon" class="icon is-pulled-right"></span><abbr title="Sort by this">Status</abbr> </th>
                </tr>
            </thead>
            <tbody>
                <tr @click="navigateTo(jobsheet)" v-for="jobsheet in filteredJobsheets" :key="jobsheet.PK" class="is-clickable">
                    <td>
                        <span class="tooltip is-tooltip-right" :data-tooltip="jobsheet.PK">{{ jobsheet.PK | shortKey }}</span>
                        <span class="heading">{{ jobsheet.GS2}}</span>
                        </td>
                    <td>
                        {{ jobsheet.CreatedBy.name }}
                        <br>
                        <span class="help">{{ jobsheet.CreatedBy.staff_id}}</span>
                        </td>
                        <td></td>
                    <td>
                        {{ jobsheet.createdAt  | formatDate }}
                        <br>
                        <span class="help">{{ jobsheet.createdAt  | formatTime }}</span>
                        </td>
                    <td>{{ jobsheet.TaskCounter}}</td>
                    <td>
                        <span class="tag" :class="{
                            'is-info': jobsheet.Status == 'OPEN',
                            'is-success': jobsheet.Status == 'COMPLETED',
                            
                        }">{{ jobsheet.Status }}</span>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import moment from 'moment'
import * as R from 'ramda'

export default {
    props: ['jobsheets', 'routeTo'],
    data(){
        return {
            filterKeyword: '',
            sortKey: 'createdAt',
            sortDescend: true,
        }
    },
    computed:{
        sortDirection(){
            return (this.sortDescend) ? 'descend' : 'ascend';            
        },
        sortIcon(){
            if (this.sortDescend)
                return `<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-sort-descending" width="44" height="44" viewBox="0 0 24 24" stroke-width="1.5" stroke="#2c3e50" fill="none" stroke-linecap="round" stroke-linejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                            <line x1="4" y1="6" x2="13" y2="6" />
                            <line x1="4" y1="12" x2="11" y2="12" />
                            <line x1="4" y1="18" x2="11" y2="18" />
                            <polyline points="15 15 18 18 21 15" />
                            <line x1="18" y1="6" x2="18" y2="18" />
                        </svg>`
            
            return `<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-sort-ascending" width="44" height="44" viewBox="0 0 24 24" stroke-width="1.5" stroke="#2c3e50" fill="none" stroke-linecap="round" stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                        <line x1="4" y1="6" x2="11" y2="6" />
                        <line x1="4" y1="12" x2="11" y2="12" />
                        <line x1="4" y1="18" x2="13" y2="18" />
                        <polyline points="15 9 18 6 21 9" />
                        <line x1="18" y1="6" x2="18" y2="18" />
                    </svg>`
        },
        filteredJobsheets(){
            let filtered = this.jobsheets.filter(data => JSON.stringify(data).toLowerCase().indexOf(this.filterKeyword) > -1)
            
            return R.sort(R[this.sortDirection](R.prop(this.sortKey)), filtered)
        }
    },
    methods:{
        sortTable(prop){
            if (this.sortKey == prop)
                this.sortDescend = !this.sortDescend            
            else
                this.sortKey = prop
        },
        navigateTo(jobsheet){
            if (this.$store.getters.om_role('processors')){
                this.$router.push(`/tasks/projects/${jobsheet.GS2}/${jobsheet.PK}`)
            }else{
                this.$router.push(`/jobs/projects/${jobsheet.GS2}/${jobsheet.PK}`)
            }
        }
    },
    filters:{
        shortKey(value){
            return R.take(12, value)
        },
        formatDate(str){
            return moment(str).format('DD MMM YYYY')
        },
        formatTime(str){
            return moment(str).format('hh:mm A')
        }
    }
}
</script>

