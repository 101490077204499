<template>
    <div v-if="selectedTask">
        <div class="card">
            <div class="card-content has-background-light">
                <span class="is-pulled-right">
                    <span class="tag" :class="{
                            'is-info' : selectedTask.status == 'ASSIGNED',
                            'is-warning' : selectedTask.status == 'UNASSIGNED',
                            'is-danger' : selectedTask.status == 'BLOCKED',
                            'is-dark' : selectedTask.status == 'CANCEL_SUBMITTED',
                            'is-success' : selectedTask.status == 'SUBMITTED' || selectedTask.status == 'COMPLETED',
                        }">{{ selectedTask.status }}</span>
                </span>
                <p>
                    <span class="heading"> {{ selectedTask.GS2 }} </span>
                    <span class="title is-6"> {{ selectedTask.details['Customer Name'] }} </span><br>
                    {{ selectedTask['Product/Service']}} <br>
                </p>
                <div class="tags" style="margin-top:20px;">
                    <span class="tag is-link">{{  selectedTask.RequestType }}</span>
                    <span class="tag is-link">{{  selectedTask['Segment/Unit'] }}</span>
                    
                </div>                
            </div>   
            
            <div class="card-content">
                <div class="columns" v-for="(value, key) in details" :key="key">
                    <div class="column is-4 has-text-right">{{ key }}</div>
                    <div class="column is-8">
                        <div class="field is-expanded">
                            <div class="field">
                                <div class="control is-expanded has-icons-right">
                                    <div class="box" style="padding:5px 10px;">
                                        <a @click="copyToCb(value, key)" :class="copyClass(key)" class="button is-small is-dark is-pulled-right" style="font-size:10px;">
                                            <span v-if="!copyStates[key]">Copy</span>
                                            <span v-if="copyStates[key]">Copied</span>
                                        </a>
                                        {{ value  || '&nbsp;'}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-content has-background-light">
                <p class="is-size-7">
                    Created by {{ selectedTask.CreatedBy.name }} on {{ selectedTask.createdAt | diffNow}}
                </p>
            </div>

        </div>
    </div>
</template>


<script>
import * as R from 'ramda'
import moment from 'moment'
import Comments from './Comments.vue'
export default {
    components:{
        Comments
    },
    props: ['selectedTask'],
    data(){
        return {
            currentForm: {
                qoute_no: '',
                order_no: ''
            },
            submitting: false,
            showCommentForm: false,
            copyStates: {

            }
        }
    },
    computed:{
        details(){
            return R.omit(['TaskID'],this.$store.getters.om_taskDetails(this.selectedTask.details)  )
        },
    },
    methods:{
        copyToCb(value, key){
            var vm = this;
            vm.$set(vm.copyStates,key,'loading')
            this.$copyText(value).then(function (e) {
                setTimeout(() => {
                    vm.$set(vm.copyStates,key,'copied')
                    setTimeout(() => {
                        vm.$set(vm.copyStates,key,false)
                    }, 500)
                },300)
            }, function (e) {
                alert('Can not copy')
                console.log(e)
            })
            
        },
        copyClass(key){
            return {
                'is-loading': this.copyStates[key] == 'loading',
                'is-success': this.copyStates[key] == 'copied',
            }
        }
    },
    filters:{
        diffNow(value){
            return moment(value).fromNow()
        }
    }
}
</script>
