<template>
    <div class="container" :style="$store.getters.secure_bg">
        <div class="columns">
            <div class="column">
                <router-link to="/dropfiles?status=OPEN" class="box">
                    <div class="level is-mobile">
                    <div class="level-left">
                        <div class="pending-item-icon has-text-warning">
                        <svg xmlns="http://www.w3.org/2000/svg" width="42" viewBox="0 0 24 24">
                            <path
                            d="M20 2H4c-1.1 0-1.99.9-1.99 2L2 22l4-4h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zM6 14v-2.47l6.88-6.88c.2-.2.51-.2.71 0l1.77 1.77c.2.2.2.51 0 .71L8.47 14H6zm12 0h-7.5l2-2H18v2z"
                            ></path>
                        </svg>
                        </div>
                        <div>
                        <strong>Pending Reviews</strong>
                        <div class="help">Pending dropfile to be review</div>
                        </div>
                    </div>
                    <div class="level-right">
                        <span class="title is-1">{{ stats.open }}</span>
                    </div>
                </div>
                </router-link>
            </div>

            <div class="column">
                <router-link to="/dropfiles?status=ON_HOLD" class="box">
                    <div class="level is-mobile">
                    <div class="level-left">
                        <div v-if="stats.onHoldOverdue > 0" class="pending-item-icon has-text-danger animated flash infinite">
                            <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"><polygon points="7.86 2 16.14 2 22 7.86 22 16.14 16.14 22 7.86 22 2 16.14 2 7.86 7.86 2"></polygon></svg>
                        </div>
                        <div v-else class="pending-item-icon has-text-primary">
                            <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"><polygon points="7.86 2 16.14 2 22 7.86 22 16.14 16.14 22 7.86 22 2 16.14 2 7.86 7.86 2"></polygon></svg>
                        </div>
                        
                        <div>
                        <strong>On hold Request</strong>
                        <div class="help">Pending dropfile to be review</div>
                        </div>
                    </div>
                    <div class="level-right">
                        <span class="title is-1">{{ stats.onHold }}</span>
                    </div>
                </div>
                </router-link>
            </div>

            <div class="column">
                <a @click="showJobsheet('OPEN')" class="box">
                <div class="level is-mobile">
                <div class="level-left">
                    <div class="pending-item-icon has-text-info">
                    <svg xmlns="http://www.w3.org/2000/svg" width="42" viewBox="0 0 24 24">
                        <path
                        d="M19 3h-4.18C14.4 1.84 13.3 1 12 1c-1.3 0-2.4.84-2.82 2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-7 0c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zm0 4c1.66 0 3 1.34 3 3s-1.34 3-3 3-3-1.34-3-3 1.34-3 3-3zm6 12H6v-1.4c0-2 4-3.1 6-3.1s6 1.1 6 3.1V19z"
                        ></path>
                    </svg>
                    </div>
                    <div>
                    <strong>In Progress</strong>
                    <div class="help">In progress jobsheets</div>
                    </div>
                </div>
                <div class="level-right">
                    <span class="title is-1">{{ stats.inprogress }}</span>
                </div>
                </div>
                </a>
            </div>

            <div class="column">
                <a @click="showJobsheet('COMPLETED')" class="box">
                    <div class="level is-mobile">
                    <div class="level-left">
                    <div class="pending-item-icon has-text-success">
                        <svg xmlns="http://www.w3.org/2000/svg" width="42" viewBox="0 0 24 24">
                        <path
                            d="M19 3h-4.18C14.4 1.84 13.3 1 12 1c-1.3 0-2.4.84-2.82 2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-7 0c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zm-2 14l-4-4 1.41-1.41L10 14.17l6.59-6.59L18 9l-8 8z"
                        ></path>
                        </svg>
                    </div>
                    <div>
                        <strong>Completed Jobsheets</strong>
                        <div class="help">&nbsp;</div>
                    </div>
                    </div>
                    <div class="level-right">
                    <span class="title is-1"> {{ stats.completed }}</span>
                    </div>
                </div>
                </a>
            </div>
            
        </div>
        <div v-if="displayedJobsheets.length > 0" class="card">
            <table-jobsheets :jobsheets="displayedJobsheets" />
        </div>
        
    </div>
</template>


<script>
import * as R from 'ramda'
import moment from 'moment'
import TableJobsheets from './../../TableJobsheets.vue'
export default {
    components:{
        TableJobsheets
    },
    data(){
        return {
            displayedJobsheets: [],
        }
    },
    mounted(){
        this.loadData()
    },
    methods:{
        loadData(){
            this.$store.dispatch('om.jobs.load')
            this.$store.dispatch('om.dropfile.all')
            this.$store.dispatch('om.stats.load')
        },
        showJobsheet(type){
            this.displayedJobsheets = this.jobsheets[type]
        }
    },
    computed:{
        stats(){
						return Object.assign({}, this.$store.state.om.stats, {
								completed: (this.jobsheets.COMPLETED||[]).length
						})
//             return {
//                 ...(this.$store.state.om.stats || {}),
//                 completed: (this.jobsheets.COMPLETED||[]).length
//             }
        },
        user(){
            return this.$store.state.login.user
        },
        jobsheets(){
            const byMe = data => data.CreatedBy.staff_id == this.user.staff_id
            return R.pipe(R.values,R.filter(byMe), R.groupBy(R.prop('Status')))(this.$store.state.om.jobs||{})
        },
        dropfiles(){
            return R.values(this.$store.state.om.dropfile.files).filter(file => file.Status == 'OPEN')
        },
        hold_dropfiles(){
            return R.values(this.$store.state.om.dropfile.files).filter(file => file.Status == 'ON_HOLD')
        },
    }
}
</script>
