'use strict'
import Vue from 'vue'
import Vuex from 'vuex'
import VueRouter from 'vue-router'
import App from './App.vue'
import axios from 'axios'
import VeeValidate from 'vee-validate';
import VueClipboard from 'vue-clipboard2'
import VuexPersistance from 'vuex-persist'

Vue.use(Vuex)
Vue.use(VueRouter)
Vue.use(VeeValidate, {
    events: 'blur'
});
Vue.use(VueClipboard)

import FeatureFlag from './../../Mobile/webcomponent/common/FeatureFlag.vue'
Vue.component('FeatureFlag', FeatureFlag)


const routes = require('./routes')

import login_store from './../../Authentication/webcomponent/store'
import om_store from './store'

const token = process.env.CAPRY_TOKEN
axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
axios.interceptors.response.use(
    res => res,
    error => {
        // if (error.response) {
        //     store.dispatch("showError", error.response.data.message);
        // }
        throw error
    }
)

const vuexPersist = new VuexPersistance({
    key: 'oasys',
    modules: ['om','user-management']
})

const store = new Vuex.Store({
    // plugins:[vuexPersist.plugin],
    state: {
        isLoading: false,
        isOffline: false,
        scrollY: 0,
    },
    mutations: {
        RESTORE_MUTATION : vuexPersist.RESTORE_MUTATION,
        SET_ISLOADING(state, value) {
            state.isLoading = value
        },
        SET_OFFLINE(state, value) {
            state.isOffline = value
            state.isLoading = false
        },
        SET_SCROLLY(state, y) {
            state.scrollY = y
        }
    },
    actions: {
        scrollY(context, y) {
            context.commit('SET_SCROLLY', y)  
        },
        dismissError(context) {
            context.commit('SET_OFFLINE', false)
        },
        showError(context, message) {
            context.commit('SET_OFFLINE', message)
        },
        loading(context) {
            context.commit('SET_OFFLINE', false)
            const timer = setTimeout(() => {
                if (context.state.isLoading) {
                    context.commit('SET_OFFLINE', true)
                }
                context.dispatch('isLoaded')
            }, 30000)
            context.commit('SET_ISLOADING', timer)
            return {
                done() {
                    context.dispatch('isLoaded')
                }
            }
        },
        isLoaded(context) {
            if (context.state.isLoading) {
                clearTimeout(context.state.isLoading)
            }
            
            setTimeout(() => {
                context.commit('SET_ISLOADING', false)
            }, 500)
        }
    },
    modules: {
        login: login_store,
        om: om_store,
        'user-management': require('./../../UserManagement/webcomponent/store').default
    }
})


let router = new VueRouter({
    routes // short for `routes: routes`
})

router.beforeEach((to, from, next) => {
    store.dispatch('loading')
    next()
})

router.afterEach((to, from) => {
    console.log("STOP")
    store.dispatch('isLoaded')
})


Vue.config.productionTip = false

new Vue({
    store,
    router,
    render: h => h(App)
}).$mount('#app')
