<template>
    <section-layout>
        <template slot="header">
            <div>
                <span><span class="title is-5">Settings</span></span>
            </div>
        </template>

        <template slot="menu">
            <ul class="menu-list">
                <!-- <li><router-link :to="`/settings/account`">Account</router-link></li> -->
                <li v-if="$store.getters.om_role('supervisors') || isAdmin"><router-link :to="`/settings/config`">Config</router-link></li>
            </ul>
        </template>
    </section-layout>
</template>


<script>

import SectionLayout from './../SectionLayout.vue'

export default {
    components:{
        SectionLayout
    },
    methods:{
        migrate(){
            if(confirm("are you sure want to trigger migration?")){
                this.$store.dispatch('om.migrate')
            }
        }
    },
    computed:{
        isAdmin(){
            return this.$store.state.login.user.staff_id == 'S54377'
        }
    }
}
</script>

