var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.selectedTask)?_c('div',[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-content has-background-light"},[_c('span',{staticClass:"is-pulled-right"},[_c('span',{staticClass:"tag",class:{
                        'is-info' : _vm.selectedTask.status == 'ASSIGNED',
                        'is-warning' : _vm.selectedTask.status == 'UNASSIGNED',
                        'is-danger' : _vm.selectedTask.status == 'BLOCKED',
                        'is-dark' : _vm.selectedTask.status == 'CANCEL_SUBMITTED',
                        'is-success' : _vm.selectedTask.status == 'SUBMITTED' || _vm.selectedTask.status == 'COMPLETED',
                    }},[_vm._v(_vm._s(_vm.selectedTask.status))])]),_c('p',[_c('span',{staticClass:"heading"},[_vm._v(" "+_vm._s(_vm.selectedTask.GS2)+" ")]),_c('span',{staticClass:"title is-6"},[_vm._v(" "+_vm._s(_vm.selectedTask.details['Customer Name'])+" ")]),_c('br'),_vm._v("\n                "+_vm._s(_vm.selectedTask['Product/Service'])+" "),_c('br')]),_c('div',{staticClass:"tags",staticStyle:{"margin-top":"20px"}},[_c('span',{staticClass:"tag is-link"},[_vm._v(_vm._s(_vm.selectedTask.RequestType))]),_c('span',{staticClass:"tag is-link"},[_vm._v(_vm._s(_vm.selectedTask['Segment/Unit']))])])]),_c('div',{staticClass:"card-content"},_vm._l((_vm.details),function(value,key){return _c('div',{key:key,staticClass:"columns"},[_c('div',{staticClass:"column is-4 has-text-right"},[_vm._v(_vm._s(key))]),_c('div',{staticClass:"column is-8"},[_c('div',{staticClass:"field is-expanded"},[_c('div',{staticClass:"field"},[_c('div',{staticClass:"control is-expanded has-icons-right"},[_c('div',{staticClass:"box",staticStyle:{"padding":"5px 10px"}},[_c('a',{staticClass:"button is-small is-dark is-pulled-right",class:_vm.copyClass(key),staticStyle:{"font-size":"10px"},on:{"click":function($event){return _vm.copyToCb(value, key)}}},[(!_vm.copyStates[key])?_c('span',[_vm._v("Copy")]):_vm._e(),(_vm.copyStates[key])?_c('span',[_vm._v("Copied")]):_vm._e()]),_vm._v("\n                                    "+_vm._s(value  || ' ')+"\n                                ")])])])])])])}),0),_c('div',{staticClass:"card-content has-background-light"},[_c('p',{staticClass:"is-size-7"},[_vm._v("\n                Created by "+_vm._s(_vm.selectedTask.CreatedBy.name)+" on "+_vm._s(_vm._f("diffNow")(_vm.selectedTask.createdAt))+"\n            ")])])])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }