<template>
    <section class="column">
        <div class="columns is-centered">
            <div class="column is-6">
                <div class="card" >
                    <div class="card-header">
                        <p class="card-header-title">Access Controls</p>
                    </div>
                    <div class="">
                        <div class="tabs is-marginless">
                            <ul class="menu-list">
                                <li @click="currentRole = tab" class="is-capitalized"  v-for="(tab, i) in tabs" :key="i"><a :class="{'is-active bg-tm-orange': currentRole == tab}">{{ tab | formatLabel }}</a></li>
                            </ul>
                        </div>
                        <div class="has-background-white-bis" :class="userlistClass">
                                <div class="card-content">
                                    <div class="level">
                                        <div style="width:100%; padding-right:10px;">
                                            <v-select v-model="currentUser" class="select2 is-fullwidth has-background-white" style="width: 100%;" label="name"  :options="users" >
                                                <template slot="option" slot-scope="option">
                                                    <section>
                                                        <div class="level heading is-marginless">
                                                            <div class="level-left">
                                                                <div class="level-item">{{option.staff_id}} </div>
                                                            </div>
                                                            <div class="level-right">
                                                                <div class="level-item" v-if="option.email">{{option.email}}</div>
                                                                <div class="level-item has-text-primary has-text-weight-semibold" v-else>no email registered</div>
                                                            </div>
                                                        </div>
                                                        <span>{{option.name}}</span>
                                                    </section>
                                                </template>
                                            </v-select>
                                        </div>
                                        <div class="">
                                            <a @click="addUser" class="button is-primary">Add user</a>
                                        </div>
                                    </div>
                                </div>
                            <div style="padding-top:20px;">
                                <table class="table is-fullwidth is-hoverable" >
                                    <tbody>
                                        <tr v-for="user in currentRoleUsers" :key="user.staff_id">
                                            <td>
                                                <p @click="removeUser(user.staff_id)" class="is-pulled-right button is-small is-danger is-outlined">Remove</p>
                                                <p v-if="user.status=='Active'" @click="changeStatus(user.status,user.staff_id)" class="is-pulled-right button is-small is-success is-outlined" style="margin-right: 2px;">{{user.status}}</p>
                                                <span v-else @click="changeStatus(user.status,user.staff_id)" class="is-pulled-right button is-small is-dark is-outlined" style="margin-right: 2px;">{{user.status}}</span>
                                                
                                                {{ user.name }}  
                                                <!-- <a class="has-text-info has-text-weight-semibold" v-if="user.role=='sales'">(UK)</a> -->
                                                <br>
                                                <!-- <span class="heading">{{ user.staff_id }}</span> -->
                                                <span class="heading" v-if="user.email">( {{ user.staff_id }} ) {{ user.email }}</span>
                                                <span class="heading has-text-primary has-text-weight-semibold" v-else>no email registered</span>
                                                <!-- <span class="heading has-text-info has-text-weight-semibold" v-if="user.role=='sales'">UK</span> -->

                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="column">
                <div class="card" style="margin-bottom: 25px;">
                    <div class="card-header has-background-light">
                        <p class="card-header-title">O@sys Template</p>
                    </div>
                    <div class="card-content">
                        <template-uploader />
                    </div>
                </div>
                <div class="card">
                    <div class="card-header has-background-light">
                        <p class="card-header-title">Blockage Types</p>
                    </div>
                    <table class="table is-fullwidth is-marginless">
                        <tbody>
                            <tr v-for="(blockage,i) in filteredBlockages" :key="i">
                                <td>
                                    <a @click="removeType(blockage)" class="is-pulled-right delete"></a>
                                    {{ blockage }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="card-content has-background-light">
                        <div class="field has-addons">
                            <div class="control ">
                                <input name="newType" v-validate="'required'" v-model="newType" type="text" class="input" :class="{
                                    'is-danger': isError
                                }" placeholder="blockage type">
                            </div>
                            <div class="control">
                                <a v-if="isSaving" class="button is-primary is-loading">Add blockage type</a>
                                <a v-else @click="addNewType" class="button is-primary">Add blockage type</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import * as R from 'ramda'
import VSelect from 'vue-select'
import TemplateUploader from './TemplateUploader.vue'

export default {
    beforeRouteEnter(to,from,next){
       next(vm => {
           if(!vm.$store.getters.om_role(['supervisors']) && !vm.isAdmin){
               vm.$router.replace('/settings')
           }
       })
    },
    components:{
        VSelect,
        TemplateUploader,
    },
    data(){
        return {
            newType: '',
            newStatus: '',
            currentUser: {},
            isAddingUser: false,
            currentRole: '',
            
            capabilities: {
                'manage_project' : {},
                'user_access': {},
            },
            userlistClass: {},
            addUserClass: {},
            isSaving: false,
            removed: '',
        }
    },
    mounted(){
        this.currentRole = R.head(this.tabs)
        Promise.all([
            this.$store.dispatch('user-management.load'),
            this.$store.dispatch('om.roles.load')
        ]).then(() => {
            this.$store.dispatch('isLoaded')
        })
    },
    computed:{
        isError(){
            return this.errors.has('newType') || this.isDuplicate
        },  
        isDuplicate(){
            return this.blockages.indexOf(this.newType) > -1
        },
        roles(){
            return this.$store.state.om.user_roles || []
        },
        tabs(){
            return R.keys(this.roles)
        },
        currentRoleUsers(){
            return this.roles[this.currentRole]
        },
        users(){
            return this.$store.state['user-management'].users || []
        },
        blockages(){
            return this.$store.state.om.configs.blockages || []
        },
        filteredBlockages(){
            return this.blockages.filter(data => data !== this.removed)   
        },
        isAdmin(){
            return this.$store.state.login.user.staff_id == 'S54377'
        }
    },
    methods:{
        addNewType(){
            if(this.isError){
                return;
            }

            const values = R.uniq(R.concat(this.blockages, [this.newType]))
            this.isSaving = true;
            
            this.$store.dispatch('om.config.update',{
                type: 'blockages',
                values
            }).then(() => {
                this.isSaving = false
            })

            this.newType = ''
        },
        removeType(type){
            this.removed = type;
            this.$store.dispatch('om.config.update',{
                type: 'blockages',
                values: this.filteredBlockages
            })
        },
        addUser(){
            // add status for user
            this.$set(this.currentUser, 'status', 'Active')
            this.$store.dispatch('om.roles.addUser', {
                role: this.currentRole,
                user: this.currentUser
            }).then(() => {
                this.$store.dispatch('om.roles.currentUser', this.$store.state.login.user.staff_id)
            })
            // this.$set(this.roles[this.currentRole], this.currentUser.staff_id, this.currentUser)
            this.currentUser = {}
        },
        removeUser(staff_id){
            this.$store.dispatch('om.roles.removeUser', {
                role: this.currentRole,
                staff_id,
            }).then(() => {
                this.$store.dispatch('om.roles.currentUser', this.$store.state.login.user.staff_id)
            })
        },
        changeStatus(status,staff_id){

            if (status == 'Active') {
                this.newStatus = 'Inactive'
            } else {
                this.newStatus = 'Active'
            }
            
            this.$store.dispatch('om.roles.changeStatus', {
                role: this.currentRole,
                staff_id, status: this.newStatus
            }).then(() => {
                this.$store.dispatch('om.roles.currentUser', this.$store.state.login.user.staff_id)
            })
           
            this.roles[this.currentRole]
        },
        showAddUser(){
            this.userlistClass = 'animated fadeOutLeft faster float'
            this.addUserClass = 'animated fadeInRight faster'
            this.isAddingUser = true
        },
        closeAddUser(){
            this.userlistClass = 'animated fadeInLeft faster'
            this.addUserClass = 'animated fadeOutRight faster float'
            this.isAddingUser = true
        }
    },
    filters:{
        formatLabel(value){
            return value.replace("_", " ")
        }
    }
}
</script>

<style>
.float{
    position: absolute;
}


.v-select , .v-select .dropdown-toggle{
    width: 100%;
}

.is-clickable{
    cursor:pointer !important;
}

</style>
