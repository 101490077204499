<template>
    <div>
        <a @click="toggleModal" class="button is-small is-outlined is-fullwidth">Put on hold</a>

        <div class="modal is-active" v-if="showModal">
            <div class="modal-background"></div>
            <div class="modal-content">
                <div class="card">
                    <div class="card-content">
                        <form @submit.prevent="handleReject">
                            <div class="columns  has-text-left">
                                <div class="column">
                                    <div class="field">
                                        <label class="label">Put on hold until: {{ calendarData.selectedDate }}</label>
                                        <div class="control card calendar" :class="{'is-danger': errors.has('duedate')}">
                                            <FunctionalCalendar
                                                name="duedate"
                                                v-model="calendarData"
                                                v-validate="'required'"
                                                :sundayStart="true"
                                                :date-format="'dd/mm/yyyy'"
                                                :is-date-picker="true"  
                                                :transition="false"
                                                :isModal="false"
                                                class="is-danger"   
                                            ></FunctionalCalendar>
                                        </div>
                                    </div>        
                                </div>
                                <div class="column">
                                    <div class="field">
                                        <label class="label">Reason</label>
                                        <div class="control">
                                            <textarea v-model="reason" name="reason" v-validate="'required'" class="textarea" rows="10" :class="{'is-danger': errors.has('reason')}" placeholder="Explain why you put this request on hold"></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div class="field">
                                <div class="control">
                                    <div class="buttons">
                                        <button type="submit" class="button is-dark">Put on hold</button>
                                        <a @click="showModal = showCal = false" class="button">Cancel</a>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <button class="modal-close is-large" aria-label="close" @click="showModal = false"></button>
        </div>
    </div>
</template>

<style lang="scss" scoped>
@import "./../../../utils/styles/tm.scss";

.calendar.is-danger{
    border: solid 1px $danger;
}
</style>

<script>
import { FunctionalCalendar } from 'vue-functional-calendar';
import moment from 'moment'

export default {
    components:{
        FunctionalCalendar,
    },
    props: ['file'],
    data(){
        return {
            showModal: false,
            reason: '',
            calendarData: '',
            showCal: false,
        }
    },
    methods:{
        toggleModal(){
            this.showModal = true
            this.calendarData = moment();
        },
        handleReject(){
            this.$validator.validateAll().then(passed => {
                if(!passed){
                    return;
                }

                this.$emit('onHold', {
                    drop_id: this.file.drop_id,
                    reason: this.reason,
                    duedate: this.calendarData.selectedDate
                })
                this.showModal = false
            })
        }
    }
}
</script>
