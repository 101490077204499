<template>
    <div>
        <div  class="Processor">
            <div class="card-content has-background-light">
                <div class="level">
                    <div>
                        <span><span class="title is-5">Job Distribution</span></span>
                    </div>
                </div>
            </div>
        </div>
        <div class="column">
            <div class="columns">
            <div class="column fullheight-scroller has-background-white-bis is-paddingless" style="border-right:solid 1px hsl(0, 0%, 86%);">
                <div class="column">
                    <aside class="menu" style="padding-bottom: 40px;">
                        <p class="menu-label">
                            Tasks
                        </p>
                        <ul class="menu-list">
                            <li><router-link :to="`/jobs/unassigned`">Unassigned tasks</router-link></li>
                            <li><router-link :to="`/jobs/recent`">Recent tasks</router-link></li>
                        </ul>
                        
                        <div class="menu-label">
                            <div class="level">
                                <div>Projects</div>
                                <a v-if="$store.getters.om_has_role(['project_manager','supervisors'])" @click="projectForm = true" class="is-pulled-right is-small button">+</a>
                            </div>
                        </div>
                        <ul class="menu-list">
                            <li style="margin-bottom:10px;">
                                <input type="text" v-model="filterProject" class="input is-rounded is-small" placeholder="filter">
                            </li>
                            <li v-for="project in projects" :key="project.PK">
                                <router-link :to="`/jobs/projects/${project.PK}`">{{ project.name }}</router-link>
                            </li>
                            <li v-if="isLoading('projects')">
                                <span class="button is-text is-loading is-fullwidth"></span>
                            </li>
                        </ul>

                        <div class="menu-label">
                            <div class="level">
                                <div>Processors</div>
                            </div>
                        </div>
                        <ul class="menu-list">
                            <li>
                                <div class="control" style="padding-bottom:10px;">
                                    <input type="text" v-model="processor_filter" class="is-search input is-rounded  is-small" placeholder="Search processor">
                                </div>
                            </li>
                            <li v-for="processor in filtered_processors" :key="processor.staff_id">
                                <router-link class="is-size-7" :to="`/jobs/processors/${processor.staff_id}`">{{ processor.name }}</router-link>
                            </li>
                            <!-- <li>
                                <router-link class="is-size-7" :to="`/jobs/processors/all`"> + See all {{ processors.length }} processor list</router-link>
                            </li> -->
                        </ul>
                        
                    </aside>
                </div>
            </div>
            <div @scroll="onscroll" class="column fullheight-scroller-2 is-10 is-hidden-mobile">
                
                <router-view></router-view>
            </div>
            <new-project-form v-if="projectForm" @close="projectForm = false" @onsubmit="handleCreateProject" />
        </div>
        </div>
    </div>
</template>

<script>
import * as R from 'ramda'
import NewProjectForm from './../Projects/NewProjectForm.vue'

export default {
    beforeRouteEnter(to,from,next){
       next(vm => {
           if(!vm.$store.getters.om_role(['executives','project_manager','supervisors'])){
               vm.$router.replace('/')
           }
       })
    },
    components:{
        NewProjectForm
    },
    data(){
        return {
            processor_filter: '',
            projectForm: false,
            filterProject: '',
        }
    },
    computed:{
        headerheight(){
            console.log(this.$refs.sectionheader)
            return "Foo"
        },
        range(){
            return (new Array(50)).map(() => "fooo")
        },
        projects(){
            return R.filter(data => data.name.toLowerCase().indexOf(this.filterProject.toLowerCase()) > -1, this.$store.state.om.projects)
        },
        processors(){
            const list = this.$store.state.om.processors
            return list
        },
        filtered_processors(){
            const max_record = 100
            if(this.processor_filter.length > 0){
                return R.pipe(
                    R.filter(data => data.name.toLowerCase().indexOf(this.processor_filter.toLowerCase()) > -1),
                    R.take(max_record),
                )(this.processors)
            }else{
                return R.take(max_record, this.processors)
            }
        }
    },
    methods:{
        onscroll(ev){
            this.$store.dispatch('scrollY', ev.target.scrollTop)
        },
        isLoading(key){
            return this.$store.state.om.loadings[key]
        },
        handleCreateProject(form){
            this.$store.dispatch('om.project.create', form).then(project => {
                this.$store.dispatch('om.project.load', project.PK).then(() => {
                    this.$router.push(`/jobs/projects/${project.PK}`)
                })
            })
        },
    }
}
</script>

<style lang="sass">
@import "~bulma/sass/utilities/functions";
@import "~bulma/sass/utilities/initial-variables";
@import "~bulma/sass/utilities/derived-variables";
@import "~bulma/sass/utilities/mixins.sass"

.sectionheader
    height: 70px
    overflow: hidden
    border-bottom: solid 1px hsl(0, 0%, 86%)


.fullheight-scroller
    height: calc(100vh - 122px)
    overflow: scroll
    min-width: 240px
    z-index:0 !important


.fullheight-scroller-2
    max-width: calc(100% - 240px)
    height: calc(100vh - 122px)
    overflow: scroll

    +mobile
        min-width: 100%
    


.is-fixed
    position: fixed




.menu-list .router-link-active
    background: #FF6600
    color: #ffffff !important


.menu-list .router-link-active:hover
    background: #FF660099

</style>