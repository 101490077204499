<template>
    <div class="section has-background-" :style="secureBg">
        <supervisor-dashboard v-if="canViewDashboard('supervisors')" />
        <sales-dashboard v-else-if="canViewDashboard('sales')" />
        <personal-dashboard v-else-if="canViewDashboard('processors')" />
        <executive-dashboard v-else-if="canViewDashboard('executives')" />
        <div v-else>
            <div class="container">
                <div class="hero is-large">
                    <div class="hero-body">
                        <div class="has-text-grey">
                            <p>It seems like you don't have any permission for this site. Please contact <span class="has-text-weight-bold">Order Management Team</span> below for futher assistance.</p>
                            <ul>
                                <li>PN. YOGESWARY A.V.PERUMAL</li>
                                <li>Sharifah Salwa Binti Syed Nasharudin</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>        
    </div>
</template>

<script>
import PersonalDashboard from './roles/PersonalDashboard.vue'
import ExecutiveDashboard from './roles/ExecutiveDashboard.vue'
import SupervisorDashboard from './roles/SupervisorDashboard.vue'
import SalesDashboard from './roles/SalesDashboard.vue'
export default {
    components:{
        PersonalDashboard,
        SalesDashboard,
        SupervisorDashboard,
        ExecutiveDashboard,
    },
    mounted(){
        this.$store.dispatch('isLoaded')
    },
    methods:{
        canViewDashboard(role){
            return this.$store.getters.om_role(role)
        }
    },
    computed:{
        user(){
            return this.$store.state.login.user
        },
        secureBg(){
            return this.$store.getters.secureBg
        }
    }
}
</script>
