import hash from 'sha1'
import ExcelReader from './../webcomponent/services/ExcelReader'
import moment from 'moment'

const validate = async (file) => {

    const acceptedFormats = [
        'application/vnd.ms-excel.sheet.macroEnabled.12', // .xlsm
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // .xlsx
        'application/vnd.ms-excel', //.xls
    ]
    //TODO: validate the excel here
    console.log('Dropped file mime', file.type)
    if (!acceptedFormats.includes(file.type)) {
        throw "Invalid file format"
    }

    const data = JSON.stringify([ExcelReader.read(file), file.name, moment().unix()])
    const dropId = `${hash(data)}.${moment().unix()}`

    console.log(dropId)

    return {
        filename: file.name,
        dropId,
        status: 'open'
    }
}

export default {
    validate
}