<template>
    <div class="container">
        <div class="columns is-multiline">
            <div class="column is-12">
                <h1 class="title is-6">My Requests</h1>
            </div>
            <div class="column is-9">
                <div class="card" >
                    <div v-if="nofile" class="card-content">
                        You have no order request yet
                    </div>
                    <div v-if="!nofile" class="card-content">
                        <div class="level">
                            <div class="level-start">
                                <input type="text" v-model="filterKeyword" class="input is-rounded" placeholder="filter">
                            </div>
                        </div>
                    </div>
                    <table v-if="!nofile" class="table is-fullwidth is-hoverable">
                        <thead>
                            <tr>
                                <th colspan="2">Drop ID</th>
                                <th>file</th>
                                <th @click="sortTable('createdAt')" class="is-clickable"><span v-if="sortKey == 'createdAt'" class="icon is-pulled-right" v-html="sortIcon"></span><abbr title="Sort by this">Upload At</abbr></th>
                                <th @click="sortTable('Status')" class="is-clickable"><span v-if="sortKey == 'Status'" class="icon is-pulled-right" v-html="sortIcon"></span><abbr title="Sort by this">Status</abbr></th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="" v-for="(file, i) in filteredFiles" :key=i>
                                <td width="5" class="has-text-grey-lighter" style="padding-right:0;">
                                    {{ i + 1 }}
                                </td>
                                <td>
                                    <a class="tag tooltip" :data-tooltip="'Copy'" @click="copyData(file, $event)" >{{ file.drop_id | shortKey }}</a>
                                </td>
                                <td>
                                    <a style="display:block; width: 400px;" @click="downloadFile(file)">{{ file.details.filename }} </a>
                                </td>
                                <td>
                                    <span class="tooltip" :data-tooltip="file.createdAt | formatDate">{{ file.createdAt | fromNow  }}</span>
                                </td>
                                <td>
                                    <drop-status :file="file" />
                                </td>
                                <td>
                                    <!-- <a v-if="file.Status != 'UPLOADED'" @click="removeFile(i)" class="delete"></a> -->
                                    <router-link :to="`/dropfiles/${file.PK}`">View</router-link>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div v-if="lastKey" class="column">
                        <a @click="loadDropfiles" class="button is-fullwidth" :class="{'is-loading': isLoading}">Load More</a>
                    </div>
                </div>
            </div>
            <div class="column is-3">
                <order-request-uploader @uploaded="handleUpload" />
            </div>
        </div>
    </div>
</template>


<script>
import * as R from 'ramda'
import moment from 'moment'
import OrderRequestUploader from './OrderRequestUploader.vue'
import DropStatus from './../../Dropfiles/DropStatus.vue'

const delay = (dur) => new Promise(done => setTimeout(done, dur))

export default {
    components:{
        OrderRequestUploader,
        DropStatus,
    },
    data(){
        return {
        //    files: [],
            isLoading: false,
            filterKeyword: '',
            sortKey: 'createdAt',
            sortDescend: true,
        }
    },
    mounted(){
        this.$store.dispatch('om.dropfile.resetKey')
        this.loadDropfiles()
    },
    methods:{ 
        sortTable(prop){
            if (this.sortKey == prop)
                this.sortDescend = !this.sortDescend            
            else
                this.sortKey = prop
        },
        async downloadFile(file){
            const url = await this.$store.dispatch('om.dropfile.download', {drop_id: file.drop_id})
            window.open(url, "_BLANK")
        },
        loadDropfiles(){
            this.$store.dispatch('om.dropfile.list', this.user.staff_id)
        },
        handleUpload(file){
            this.loadDropfiles()
        },
        removeFile(drop_id){
            if(!confirm("Confirm to delete the file?")){
                return 
            }
            
            this.$store.dispatch('om.dropfile.remove', { drop_id }).then(() => {
                this.loadDropfiles()
            })
        },
        copyData(file, ev){
            this.$copyText(R.take(12,file.PK))
            ev.toElement.dataset.tooltip = "Copied"
            delay(1000).then(() => {
                ev.toElement.dataset.tooltip = "Copy"
            })
            
        },
        getRejectionReason(file){
            if(file.Status != 'REJECTED'){
                return ''
            }
            return `${file.details.rejection.reason}

-${file.details.rejection.rejectedBy.name}-`
        },
    },
    computed:{
        sortDirection(){
            return (this.sortDescend) ? 'descend' : 'ascend';            
        },
        sortIcon(){
            if (this.sortDescend)
                return `<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-sort-descending" width="44" height="44" viewBox="0 0 24 24" stroke-width="1.5" stroke="#2c3e50" fill="none" stroke-linecap="round" stroke-linejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                            <line x1="4" y1="6" x2="13" y2="6" />
                            <line x1="4" y1="12" x2="11" y2="12" />
                            <line x1="4" y1="18" x2="11" y2="18" />
                            <polyline points="15 15 18 18 21 15" />
                            <line x1="18" y1="6" x2="18" y2="18" />
                        </svg>`
            
            return `<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-sort-ascending" width="44" height="44" viewBox="0 0 24 24" stroke-width="1.5" stroke="#2c3e50" fill="none" stroke-linecap="round" stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                        <line x1="4" y1="6" x2="11" y2="6" />
                        <line x1="4" y1="12" x2="11" y2="12" />
                        <line x1="4" y1="18" x2="13" y2="18" />
                        <polyline points="15 9 18 6 21 9" />
                        <line x1="18" y1="6" x2="18" y2="18" />
                    </svg>`
        },
        lastKey(){
            return this.$store.state.om.dropfile.lastKey  
        },
        user(){
            return this.$store.state.login.user
        },
        filteredFiles(){
            let filtered = this.files.filter(data => JSON.stringify(data).toLowerCase().indexOf(this.filterKeyword) > -1)
            
            return R.sort(R[this.sortDirection](R.prop(this.sortKey)), filtered)
        },
        files(){
            return R.pipe(
                R.values
            )(this.$store.state.om.dropfile.files)
        },
        nofile(){
            return R.keys(this.files).length == 0
        }
    },
    filters:{
        fromNow(value){
            return moment(value).fromNow()
        },
        formatDate(value){
            return moment(value).format('DD MMM YYYY, hh:mm A')
        },
        shortKey(value){
            return R.take(12, value)
        },
    }
}
</script>
