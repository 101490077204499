var XLSX = require('xlsx')
const R = require('ramda')
const hash = require('sha1')
const moment = require('moment-timezone')
moment().tz("Asia/Kuala_Lumpur");

function readExcel(file) {
    return new Promise(function (resolve, reject) {
        var rABS = true;
        var reader = new FileReader()
        reader.onload = function (e) {
            var data = e.target.result;
            if (!rABS) data = new Uint8Array(data);
            var workbook = XLSX.read(data, {
                type: rABS ? 'binary' : 'array'
            });

            resolve(workbook)
        };
        if (rABS) reader.readAsBinaryString(file);
        else reader.readAsArrayBuffer(f);
    })
}

const mappers = {
    // Date: (value) => `DATE UPDATE: ${value}`
}

const defaultMapper = R.identity

const getTaskID = data => console.log(JSON.stringify(data)) || hash(JSON.stringify(data)) + "." + moment().unix()


const mapEntry = (entries, key) => {
    return R.map(entry => {
        const keys = R.map(key => {
            return R.pipe(R.trim, R.replace(/(\r\n)/g, ''))(key)
        }, R.keys(entry))
        const values = R.values(entry)

        const mapped = R.zipObj(keys, values)

        const summary = R.pick([
            'Date',
            'Order Type',
            'Segment/Unit',
            'Product/Service',
            'Ref. No.',
            'Additional Remark',
        ], mapped)

        const emptyObject = {
            'Date': '',
            'Order Type': '',
            'CustomerName': '',
            'Segment/Unit': '',
            'Product/Service': '',
            'BillingAccount': '',
            'Ref. No.': '',
            'Additional Remark': '',
        }

        return Object.assign({
            TaskID: getTaskID(mapped),
            RequestType: key,
        }, emptyObject, summary, {
            'RFS/RFT': mapped.RFS || mapped.RFT, //(moment(mapped.RFS || mapped.RFT, 'D-MMM-YY') || moment(mapped.RFS || mapped.RFT, 'D/MMM/YY')).format('YYYY-MM-DD'),
            'CustomerName': mapped['Customer Name in ICP'] || mapped['Customer Name in NOVA'] || mapped['Customer Name'],
            'BillingAccount': mapped['Billing Account No'] || mapped['Billing Account'],
            // 'Date': (moment(mapped.Date , 'D-MMM-YY') || moment(mapped.Date , 'D/MMM/YY')).format('YYYY-MM-DD'),
            details: mapped,
        })

    }, entries)
}

function getOrders(workbook) {
    const process = R.pipe(
        R.mapObjIndexed((sheet, key) => {
            return mapEntry(XLSX.utils.sheet_to_json(sheet, {
                raw: false
            }), key)
        }),
        R.values,
        R.flatten
    )

    return process(workbook.Sheets)
}

function getHeaders(workbook) {
    const process = R.pipe(
        R.omit(['Home']),
        R.mapObjIndexed((sheet, key) => {
            const data = XLSX.utils.sheet_to_json(sheet, {
                header: 1,
                raw: false
            })

            const header = data[3].map(data => data.trim())
            return header
        })
    )

    const output = process(workbook.Sheets)

    return output
}

function getOrders2(workbook) {
    const process = R.pipe(
        R.omit(['Home']),
        R.mapObjIndexed((sheet, key) => {
            const data =  XLSX.utils.sheet_to_json(sheet, {
                header: 1,
                raw: false  
            })
            
            //check if not the template sheet
            if (!data[3] || R.isEmpty(data[3])) {
                return []
            }

            const header = data[3].map(data => data.trim())
            const rows = R.pipe(R.splitAt(4), data => data[1], R.filter(data => data.length > 5))(data)
            
            return rows.map(row => R.zipObj(header, row)).map(data => R.mergeAll([data, {
                'CustomerName': data['Customer Name in ICP'] || data['Customer Name in NOVA'] || data['Customer Name'],
                TaskID: getTaskID(data),
                RequestType: key,
            }]))
        }),
        R.values,
        R.flatten
    )
    
    const output = process(workbook.Sheets)

    console.log(R.pipe(
        R.map(R.keys),
        R.flatten,
        R.groupBy(R.identity),
        R.map(data => data.length)
    )(output))

    return output
}   

export default {
    read: function (file) {
        return readExcel(file).then(workbook => {
            return getOrders2(workbook)
        })
    },
    headers: function (file) {
        return readExcel(file).then(workbook => {
            return getHeaders(workbook)
        })
    }
    
}